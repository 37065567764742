html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  box-sizing: border-box;
  color: #dfe0e8;
  letter-spacing: 0;
  min-width: fit-content;
}

p,
h1,
h2,
h3,
ul,
li {
  margin: 0;
  padding: 0;
}

.player_container{
  border-radius: 12px;
  overflow: hidden;
  padding: 24px;
  font-family: 'Montserrat';
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .title{
    font-weight: normal;
  }

  input[type=range]{
    --bar-bg: #1941D0;
    --seek-before-width: 0;
    --seek-before-color: #FF426C;
    --knobby: #FF426C;
    --selected-knobby: #FF426C;

    appearance: none;
    outline: none;
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 4px;
    cursor: pointer;
    &::before{
      content: '';
      height: 4px;
      width: var(--seek-before-width);
      background-color: var(--seek-before-color);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;
    }
    // knobby - chrome and safari
    &::-webkit-slider-thumb{
      -webkit-appearance: none;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      border: none;
      background-color: var(--knobby);
      cursor: pointer;
      position: relative;
      margin: -2px 0 0 0;
      z-index: 3;
      box-sizing: border-box;
    }
    //knobby while dragging - chrome and safari
    &:active::-webkit-slider-thumb{
      transform: scale(1.2);
      background-color: var(--selected-knobby);
    }
  }
  //progress bar - safari
  .progressBar::-webkit-slider-runnable-track{
    appearance: none;
    outline: none;
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 4px;
  }
  //progress bar - firefox
  .progressBar::-moz-range-track{
    appearance: none;
    outline: none;
    background: var(--bar-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 4px;
  }
  .progressBar::-moz-focus-outer{
    border: 0;
  }

  //progress bar - firefox
  .progressBar::-moz-range-progress{
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 4px;
  }
  // knobby - firefox
  .progressBar::-moz-range-thumb{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }
  //knobby while dragging - firefox
  .progressBar:active::-moz-range-thumb{
    transform: scale(1.2);
    background-color: var(--selected-knobby);
  }

  .share_component, .options_component{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4  ;
    padding: 43px;
    display: flex;
    border-radius: 12px;

    flex-direction: column;
    align-items: flex-start;
    --bg-color: #3f426b;
    --text-color: #FFB12D;
    //.copy_link_hint{
    //  position: absolute;
    //  top: 6px;
    //  left: 35px;
    //  height: 26px;
    //  width: 90px;
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
    ////  background-color: #252746;
    ////  border-radius: 3px;
    ////  font-size: 12px;
    ////  line-height: 15px;
    ////  color: #FFB12D;
    ////  &::before{
    ////    content: '';
    ////    position: absolute;
    ////    bottom: -7px;
    ////    left: 10px;
    ////    width: 0;
    ////    height: 0;
    ////    border-left: 10px solid transparent;
    ////    border-right: 10px solid transparent;
    ////
    ////    border-top: 8px solid #252746;
    ////
    ////  }
    //}
      .copy_link_hint{
        position: absolute;
          top: 6px;
          left: 35px;
        height: 26px;
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bg-color);
        border-radius: 3px;
        font-size: 12px;
        line-height: 15px;
        color: var(--text-color);
        &::before{
          content: '';
          position: absolute;
          bottom: -7px;
          left: 10px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;

          border-top: 8px solid var(--bg-color);

        }

    }


    .copy_link {
      left: 14px;
      top: 30px;
    }

    a{
      text-decoration: none;
      color: inherit;
    }

    .close_button{
      all: unset;
      margin-left: auto;
      cursor: pointer;

    }
    .share_component_link{
      text-decoration: none;
      font: normal normal normal 12px/15px Montserrat;
      letter-spacing: 0;
      color: #FF426C;
      margin-bottom: 5px;
    }
    .share_component_privacy_policy{
      margin-top: auto;
    }
    .share_component_button{
      all: unset;
      font: normal normal normal 16px/19px Montserrat;
      letter-spacing: 0;
      margin-bottom: 17px;
      display: flex;
      align-items: center;
      cursor: pointer;
      //&:last-child{
      //  margin-bottom: auto;
      //}
      svg{
        margin-right: 15px;
      }
    }

  }
  .options_component{
    padding-bottom: 12px;
  }
  .share_options{
    position: absolute;
    display: flex;
    align-items: center;
    .embed_share{
      all: unset;
      cursor: pointer;
      margin-right: 13px;
    }
    .embed_options{
      all: unset;
      cursor: pointer;
    }
  }
  .logo_link{
    font: normal normal 600 15px/20px "Montserrat";
    letter-spacing: 0;
    color: #DFE0E8;
    text-decoration: none;
    svg{
      width: 55px;
    }
  }
  &.dark{
    background-color: #0F102B;
    color: #DFE0E8;
    .share_component, .options_component{
      background-color: #0F102B;

      svg{
        fill:#DFE0E8;
      }
    }
  }
  &.light{
    background-color: #DFE0E8;
    color: #0F102B;
    .logo_link{
      color: #0F102B;
    }
    .share_component, .options_component{
      background-color: #DFE0E8;
      .close_button_icon{
        color: #0F102B;
      }
      .share_component_button{
        color: #0F102B;
      }
      svg{
        fill:#0F102B;
      }
    }
  }
  &.one{
    width: 547px;
    .left_part{
      margin-right: 40px;
      position: relative;

      .logo_wrapper{
        width: 80px;
        height: 80px;
        margin-bottom: 17px;
      }
      .title, .company_name{
        width: 215px;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title{
        font-size: 20px;
        line-height: 24px;
      }
      .company_name{
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }
    }
    .cover_wrapper{
      position: relative;
      width: 241px;
      height: 241px;
      cursor: auto !important;

      .upload_button_dark{
        svg{
          fill: #DFE0E8
        }
      }
      .upload_button_light{
        svg{
          fill: #0F102B
        }
      }

      .upload_button{
        all: unset;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font: normal normal 600 16px/19px Montserrat;
        letter-spacing: 0;
        color: #DFE0E8;
        cursor: pointer;

        .upload_button_text{
          padding-top: 7px;
        }
        .upload_button_text_light{
          padding-top: 7px;
          color: #0F102B;
        }
      }
      .hidden_upload_button{
        @extend .upload_button;
        display: none;
      }
      .episode_cover {
        width: 237px;
        height: 237px;
        overflow: hidden;
        object-fit: cover;
      }
    }
    .logo_link{
      display: none;
    }
    .share_options{
      top: 0;
      right: 0;
    }
  }
  &.two{
    width: 250px;
    height: 250px;
    padding: 16px;


    .left_part{
      .logo_wrapper{
        width: 90px;
        height: 90px;
        margin-bottom: 13px;
      }

      .title, .company_name{
        width: 215px;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title{
        font-size: 16px;
        line-height: 19px;
      }
      .company_name{
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .embed_player{
        height: auto;
        .duration{
          padding-bottom: 0;
          .duration_passed, .duration_left{
            font-size: 9px;
            line-height: 11px;
              margin-bottom: 0;
          }
        }
      }
    }
    .upload_button{
      display: none;
    }
    .logo_link{
      position: absolute;
      top: 12px;
      right: 13px;
    }
    .share_options{
      right: 13px;
      top: 42px;
    }
    .share_component, .options_component{
      padding: 36px 15px 15px 25px;
      .copy_link_hint{
        top: 2px;
        left: 18px;
      }
    }
    .cover_wrapper{
      display: none;
    }

  }
  &.three{
    width: 586px;
    height: 151px;
    padding: 17px 15px 15px 17px;
    .left_part{
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .logo_wrapper{
        width: 115px;
        height: 115px;
        margin-right: 27px;
      }
      .title, .company_name{
        width: 320px;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title{
        margin-bottom: 0;
        font-size: 16px;
        line-height: 19px;
      }
      .company_name{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
      }
      .embed_player{
        height: 82px;
        width: 320px;
        .timeline{
          width: 320px;
          .passed{
          }
        }
        .duration{
          padding-bottom: 0;
          padding-top: 2px;
          .duration_passed, .duration_left{
            font-size: 9px;
            line-height: 11px;
            margin-bottom: 0;

          }
        }
      }
    }
    .upload_button{
      display: none;
    }
    .logo_link{
      position: absolute;
      top: 8px;
      right: 15px;
    }
    .share_options{
      right: 15px;
      bottom: 19px;
    }
    .cover_wrapper{
      display: none;
    }
    .share_component, .options_component{
      padding: 29px 24px;
      .copy_link_hint{
        top: 0;
        left: 15px;
      }
      .share_component_privacy_policy{
        margin-top: 0;
        position: absolute;
        bottom: 13px;
        right: 185px;
      }

      .share_component_term_of_conditions{
        position: absolute;
        bottom: 13px;
        right: 23px;
      }
    }
  }
  &.four{
    width: 872px;
    height: 118px;
    padding: 14px 15px;
    .left_part{
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .logo_wrapper{
        width: 90px;
        height: 90px;
        margin-right: 16px;
      }
      .title, .company_name{
        width: 150px;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title{
        margin-bottom: 1px;
        font-size: 16px;
        line-height: 19px;
        width: 150px;
        margin-right: 10px;
        padding-top: 23px;
      }
      .company_name{
        font-size: 14px;
        line-height: 18px;
        width: 150px;
        margin-right: 10px;
      }
      .embed_player{
        padding-top: 2px;
        height: auto;
        width: 364px;
        .timeline{
          width: 364px;

        }
        .duration{
          padding-bottom: 0;
          .duration_passed, .duration_left{
            font-size: 10px;
            line-height: 13px;
            margin-bottom: 0;

          }
        }
      }
    }

    .upload_button{
      display: none;
    }
    .cover_wrapper{
      display: none;
    }
    .share_component, .options_component{
      padding: 29px 24px;
      flex-direction: row;
      align-items: center;
      .share_component_button{
        margin-right: 40px;
        margin-bottom: 0;
      }
      .share_component_privacy_policy{
        margin-top: 0;
        position: absolute;
        bottom: 13px;
        right: 210px;
      }

      .share_component_term_of_conditions{
        position: absolute;
        bottom: 13px;
        right: 48px;
      }
    }

    .logo_link{
      position: absolute;
      top: 11px;
      right: 15px;
    }
    .share_options{
      right: 95px;
      top: 47px;
    }
  }

  .left_part{
    height: 100%;
    .logo_wrapper{
      background-color: #fafafa;
    }
    .title{
      margin-bottom: 2px;
    }
    .company_name{
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
    }
    .embed_player{
      height: 90px;
      width: 215px;
      border-radius: 50%;
      .timeline{
        width: 215px;
        background-color: #1941D0;
        height: 2px;
        .passed{
          height: 2px;
          background-color: #FF426C;
          width: 40%;
          position: relative;
          &:after{
            content: '';
            position: absolute;
            top: 50%;
            transform: translate(50%, -50%);
            right: 0;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #FF426C;

          }
        }
      }
      .duration{
        padding: 5px 0;

        display: flex;
        justify-content: space-between;
        .duration_passed, .duration_left{
          font-size: 11px;
          line-height: 14px;
          margin-bottom: 0;

        }

      }
      .controls{
        display: flex;
        justify-content: center;
        .rewind_button{
          all: unset;
          cursor: pointer;
          &:hover{
            transition: 0.3s;
            transform: scale(1.15);
          }
        }
        .rewind_back_button{
          margin-right: 20px;
        }
        .big_oval{
          width: 51px;
          height: 51px;
          margin-right: 20px;
          background-image: url("../Embed/assets/bigOval.png");
          background-size: contain;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .small_oval{
            height: 46px;
            width: 44px;
            background-image: url("../Embed/assets/smallOval.png");
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            .play{
              all: unset;
              position: relative;
              left: 3px;
              top:1px;
            }
          }
        }
        .custom_play_button_wrapper{
          margin-right: 20px;
          position: relative;
          cursor: pointer;
          border-radius: 50%;

          .player_button_icon{
            svg{
              width: 25px;
              height: 27px;
              pointer-events: none;
            }
          }
          .pause_button_icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .play_button_icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-37%, -50%);
          }

        }
        .custom_play_button{
          all: unset;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          opacity: 0.43;

        }
      }
    }
  }
  .share_component, .options_component{
    .close_button{
      position: absolute;
      top: 10px;
      right: 13px;
    }
  }
  //.MuiSlider-thumb:not(.MuiSlider-active) {
  //  transition: left 1s ease-in;
  //}
  //.MuiSlider-track {
  //  transition: width 1s ease-in;
  //}


}
